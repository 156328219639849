// BootstrapのJavaScript側の機能を読み込む
import "bootstrap";
// jQuery
window.$ = window.jQuery = require('jquery');

// スタイルシートを読み込む
import "./index.scss";

/*===========================================================*/
/* JavaScript
/*===========================================================*/
// ローディング
window.addEventListener('load', function () {
  const spinner = document.getElementById('loading');
  spinner.classList.add('loaded');
});

/*===========================================================*/
/* jQuery
/*===========================================================*/
// ページが読み込まれたらすぐに実行
$(window).on('load resize', function () {
  mediaQueriesWin();
  fadeAnime();
  accordionInit();
});
// 992px以上の場合、.sub-menuを画面中央に
$('#menu-global .menu-item-has-children').on({
  'mouseenter': function () {
    let width = $(window).width();
    if (width < 992) return false;
    // let subMenuOffset = $(this).children('.sub-menu').offset();
    let subMenuWidth = $(this).children('.sub-menu').width();
    let leftPosition = (width - subMenuWidth) / 2; // 表示させたい左端
    let menuGlobalOffset = $('#menu-global').offset(); // 親要素の左位置
    let menuGlobalLeft = menuGlobalOffset.left;

    // container
    let containerWidth = (width - $('.container').width()) / 1.25;

    $(this).children('.sub-menu').css('left', leftPosition - (menuGlobalLeft + containerWidth)); // 中央揃え
  },
  'mouseleave': function () {
    let width = $(window).width();
    if (width < 992) return false;
    $(this).children('.sub-menu').css('left', '');
  }
});
// クリックしたら円形背景が拡大（左上から）
$(".openbtn").on('click', function () { //ボタンがクリックされたら
  $(this).toggleClass('active'); //ボタン自身に activeクラスを付与し
  $("#gnav").toggleClass('panelactive'); //ナビゲーションにpanelactiveクラスを付与
  $(".circle-bg").toggleClass('circleactive'); //丸背景にcircleactiveクラスを付与
});

$("#gnav a").on('click', function () { //ナビゲーションのリンクがクリックされたら
  $(".openbtn").removeClass('active'); //ボタンの activeクラスを除去し
  $("#gnav").removeClass('panelactive'); //ナビゲーションのpanelactiveクラスを除去
  $(".circle-bg").removeClass('circleactive'); //丸背景のcircleactiveクラスを除去
});
// アコーディオン
$('.item-question').on('click', function () {
  let findElm = $(this).next('.item-answer');
  $(findElm).slideToggle();
  if ($(this).hasClass('close')) {
    $(this).removeClass('close');
  } else {
    $(this).addClass('close');
  }
});
/*----------*/
/* functions
/*----------*/
// 動きのきっかけの起点となるアニメーションの名前を定義
function fadeAnime() {
  // ふわっ（下から）
  $('.fadeUpTrigger').each(function () { //fadeUpTriggerというクラス名が
    var elemPos = $(this).offset().top - 50; //要素より、50px上の
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll >= elemPos - windowHeight) {
      $(this).addClass('fadeUp'); // 画面内に入ったらfadeUpというクラス名を追記
    } else {
      $(this).removeClass('fadeUp'); // 画面外に出たらfadeUpというクラス名を外す
    }
  });
  // ふわっ（上から）
  $('.fadeDownTrigger').each(function () { //fadeDownTriggerというクラス名が
    var elemPos = $(this).offset().top - 50; //要素より、50px上の
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll >= elemPos - windowHeight) {
      $(this).addClass('fadeDown'); // 画面内に入ったらfadeDownというクラス名を追記
    } else {
      $(this).removeClass('fadeDown'); // 画面外に出たらfadeDownというクラス名を外す
    }
  });
}
// ドロップダウンの設定
function mediaQueriesWin() {
  var width = $(window).width();
  if (width < 992) { //横幅が768px以下の場合
    $(".menu-item-has-children>a").off('click'); //menu-item-has-childrenクラスがついたaタグのonイベントを複数登録を避ける為offにして一旦初期状態へ
    $(".menu-item-has-children>a").on('click', function () { //menu-item-has-childrenクラスがついたaタグをクリックしたら
      var parentElem = $(this).parent(); // aタグから見た親要素の<li>を取得し
      $(parentElem).toggleClass('active'); //矢印方向を変えるためのクラス名を付与して
      $(parentElem).children('ul').stop().slideToggle(500); //liの子要素のスライドを開閉させる※数字が大きくなるほどゆっくり開く
      return false; //リンクの無効化
    });
  } else { //横幅が768px以上の場合
    $(".menu-item-has-children>a").off('click'); //menu-item-has-childrenクラスがついたaタグのonイベントをoff(無効)にし
    $(".menu-item-has-children").removeClass('active'); //activeクラスを削除
    $('.menu-item-has-children').children('ul').css("display", ""); //スライドトグルで動作したdisplayも無効化にする
  }
}
// アコーディオン
function accordionInit() {
  $('.wrap-accordion > accordion-item:first-child').addClass('open');
  $('.accordion-item.open').each(function (index, element) {
    let itemQ = $(element).children('.item-question');
    $(itemQ).addClass('close');
    let itemA = $(element).children('.item-answer');
    $(itemA).slideDown(500);
  });
}
// スマートフォン時、.tel-link クラスに a 要素を追加する ※タップしてTELすることができる
const device = navigator.userAgent;
if ((device.indexOf('iPhone') > 0 && device.indexOf('iPad') == -1) || device.indexOf('iPod') > 0 || device.indexOf('Android') > 0) {
  $('.tel-link').each(function () {
    var str = $(this).text();
    $(this).html($('<a class="">').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
  });
}